<template>
  <div class="page">
    <div class="relative bg-white content">
      <div class="flex">
        <van-search v-model="likeName"
                    placeholder="搜索"
                    shape="round"
                    class="flex-1"
                    @search="getData()" />
        <van-button type="primary"
                    plain
                    round
                    size="small"
                    class="mr-3"
                    style="padding: 4px 13px"
                    @click="syncGroup()">同步</van-button>
      </div>
      <van-divider class="mt-1" />
      <div class="flex fs-14">
        <van-checkbox :checked="checked"
                      shape="square"
                      class="ml-3 flex-1"
                      @click="allChose()">
          <div>全选</div>
        </van-checkbox>
        <div class="flex-right color-gray mr-3">
          已选总数：<span class="color-danger">{{ [...checkValue].length }}</span>
        </div>
      </div>
      <van-divider class="mt-2" />
      <ul class="content">
        <li v-for="(el, index) in groupList"
            :key="el.chatroomId"
            class="mb-5 ml-3">
          <van-checkbox :checked="checkValue.has(el.chatroomId)"
                        shape="square"
                        @click="onChange(el)">
            <div class="text-flow">{{ index + 1 }}、{{ el.groupName }}</div>
          </van-checkbox>
        </li>
      </ul>
      <div class="tips">开启托管后的群，才可以设置关键词回复</div>
    </div>
    <div class="p-footer h-end">
      <div class="flex  flex-right">
        <button class="btn auto"
                @click="submit(0)">取消</button>
        <button class="btn auto fill ml-3"
                @click="submit(1)">保存</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ChoseGroup',
  components: {},
  data () {
    return {
      loading: false,
      robot: {
        nickName: '宫姣栋',
        headImgUrl: '',
        online: 2,
        openNum: 0,
        allNum: 30
      },
      pageIndex: 1,
      pageSize: 1000,
      likeName: '',
      checkValue: new Map(),
      checked: false,
      result: ['a', 'b'],
      groupList: [],
      openState: 0,
      additionalVal: {},
      chatroomList: []
    }
  },
  created () {
    const _this = this
    _this.getGlobal()
    _this.getData()
  },
  computed: {
    ...mapGetters(['robotInfo', 'contentData'])
  },
  methods: {
    getGlobal () {
      const _this = this
      if (_this.contentData && _this.contentData.choseGroup) {
        var newMap = new Map(_this.contentData.choseGroup)
        _this.checkValue = newMap
      }
    },
    getData () {
      const _this = this
      _this.$post('/WxRobot/QueryChatrooms', {
        pageIndex: _this.pageIndex,
        pageSize: _this.pageSize,
        sortName: '',
        sortOrder: 0,
        robotWxIds: [_this.robotInfo.robotWxId],
        likeName: _this.likeName,
        openState: 2,
        openType: 3
      }).then((res) => {
        _this.groupList = res.dataList
        _this.additionalVal = res.additionalVal

        if ([..._this.checkValue.keys()].length === _this.groupList.length) {
          _this.checked = true
        } else {
          _this.checked = false
        }
      })
    },
    async syncGroup () {
      const _this = this
      await _this.$get('/Common/SyncRobotChatRoom', {
        robotWxId: _this.robotInfo.robotWxId
      }).then((res) => { })
      _this.getData()
    },
    onChange (item) {
      const _this = this
      if (_this.checkValue.has(item.chatroomId)) {
        _this.checkValue.delete(item.chatroomId)
      } else {
        _this.checkValue.set(item.chatroomId, true)
      }
      if ([..._this.checkValue.keys()].length === _this.groupList.length) {
        _this.checked = true
      } else {
        _this.checked = false
      }

    },
    allChose () {
      const _this = this
      _this.checked = !_this.checked
      _this.checkValue.clear()
      if (_this.checked) {
        _this.groupList.map((item) => {
          _this.checkValue.set(item.chatroomId, true)
        })
      }
    },
    submit (n) {
      const _this = this
      if (n === 1) {
        this.$store.commit('setContentData', {
          choseGroup: this.checkValue
        })
      }
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
